<template>
  <b-row>
    <b-col cols="12">
      <!-- Default Colors -->
      <b-card
        title="Outil d'export de dispatch"
        class="background-color"
      >

        <b-row>
          <b-col md="4">
            <b-form-group>
              <h5>Date d'export :</h5>
              <flat-pickr
                v-model="rangeDate"
                placeholder="Choisissez une date"
                class="form-control"
                :config="{ mode: 'range'}"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <h5>Logisticien :</h5>
              <v-select
                v-model="selected"
                placeholder="Choisissez un logisticien"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="option"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="text-center"
            md="8"
          >
            <b-form-group>
              <b-button
                variant="primary"
                :disabled="loading"
                @click="dispatch"
              >
                Générer le fichier
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        title="Convertisseur Fichier Agediss"
        class="background-color"
      >
        <b-row class="">
          <b-col md="4">
            <a href="https://docs.google.com/spreadsheets/d/1OLR97Rthw1KgO2InfXF3vKZF_Tg8vPug8c1J9YBRjkA/edit?usp=sharing" target="_blank"><u>Accéder au template</u></a>
            <h5 class="mt-2">Fichier :</h5>
            <b-form-file
              v-model="file"
              placeholder="Choisir un fichier ou glisser..."
              drop-placeholder="Drop fichier ici..."
              accept=".csv"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="text-center mt-2"
            md="2"
          >
            <b-form-group>
              <b-button
                variant="primary"
                :disabled="loading"
                @click="upload"
              >
                Convertir
              </b-button>
            </b-form-group>
          </b-col>
          <b-col
            class="text-center mt-2"
            md="5"
          >
            <b-form-group>
              <b-button
                variant="outline-primary"
                :disabled="loading"
                @click="upload(true)"
              >
                Convertir & Déposer sur le serveur
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    <!--/ Gradient color start -->
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BFormFile,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    flatPickr,
    vSelect,
    BFormFile,
    BFormGroup,
    BButton,
    BRow,
    BCol,
  },
  data() {
    return {
      rangeDate: null,
      loading: false,
      file: null,
      selected: '',
      option: ['Agediss', 'Car', 'GommaGomma (IT)', 'GommaGomma (FR/DE/ES)', 'Flexilan', 'Futon', 'Canapé', 'Loupiote', 'Rideaux', 'Algo', 'Timeless', 'Luna', 'DEB', 'DBSCHENKER'],
    }
  },
  methods: {
    async dispatch() {
      try {
        this.loading = true
        if (this.selected === '') {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Vous devez sélectionner un logisticien.',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return false
        }
        if (!this.rangeDate) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Vous devez sélectionner une date de dispatch.',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return false
        }
        const data = await this.$http({
          url: '/dispatch',
          method: 'POST',
          responseType: 'blob',
          data: {
            selected: this.selected,
            rangeDate: this.rangeDate,
          },
        })
        const fileURL = window.URL.createObjectURL(new Blob([data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', `dispatch-${this.selected}-${this.rangeDate}.csv`)
        document.body.appendChild(fileLink)

        fileLink.click()
        return data
      } catch (err) {
        return err
      } finally {
        this.loading = false
      }
    },
    async upload(depot = false) {
      if (!this.file) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Vous devez mettre un fichier.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        setTimeout(() => {}, 2000)
        const form = new FormData()
        form.append('file', this.file, this.file.name)
        try {
          this.loading = true
          const { link } = await this.$http.post(`/admin/log/converter${depot ? '?onServer=true' : ''}`, form)
          this.file = null
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Bien converti !',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          window.open(link)
        } catch (err) {
          console.log(err)
        } finally {
          this.loading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
.colors-list {
  li {
    padding: 0.4rem;
    svg {
      margin-right: 0.25rem;
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
